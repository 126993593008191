<template>
    <b-form @submit.prevent="saveHomework(false)">
        <b-form-group label="Задание:" class="col-12" label-for="input-task_title">
            <b-form-textarea
                id="input-task_title"
                class="textarea"
                v-model="form.task"
            />
        </b-form-group>

        <b-form-group label="Комментарий:" class="col-12" label-for="input-comment_title">
            <b-form-textarea
                id="input-comment_title"
                class="textarea"
                v-model="form.comment"
            />
        </b-form-group>

        <b-form-group label="Ссылка:" class="col-12" label-for="input-link_title">
            <b-form-input
                id="input-link_title"
                class="input"
                v-model="form.link"
            />
        </b-form-group>

        <b-form-group label="Статус:" class="col-12" label-for="input-status">
            <multiselect
                id="input-status"
                label="title"
                :close-on-select="true"
                :options="statuses"
                :value="statuses.filter(v => v.id === form.status)[0]"
                @input="(data) => form.status = data.id"
            />
        </b-form-group>

        <b-form-group class="col-12 text-right">
            <b-button type="button" class="mr-2" variant="outline-primary" @click="handleReviewClick">
                <span>Отправить на доработку</span>
            </b-button>
            <b-button type="submit" class="mr-2" variant="success">
                <span>Сохранить</span>
            </b-button>
            <b-button type="reset" variant="primary" @click="closeModal">Закрыть</b-button>
        </b-form-group>
    </b-form>
</template>

<script>
import { formPreparation } from "@/mixins/form/form-mixin";
import Multiselect from "vue-multiselect";

export default {
    name: "HomeworkWithLink",
    components: {
        Multiselect,
    },
    mixins: [formPreparation],
    props: {
        homework: {
            required: true,
        },
        lessonId: {
            required: true,
            type: [Number, String],
        },
        statuses: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            form: {
                task: null,
                link: null,
                comment: null,
                status: null,
                remove_files: [],
            },
        };
    },
    created() {
        if (this.homework) {
            Object.keys(this.homework).forEach(key => {
                if (key === "files") {
                    // заполняем файлы для удаления
                    let files = this.homework[key];

                    Object.keys(files).forEach(j => {
                        if (j === 'task') {
                            files[j].forEach(file => {
                                this.form.remove_files.push(file.id);
                            });
                        }
                    });
                } else {
                    this.form[key] = this.homework[key];
                }
            });
        }
    },
    methods: {
        async handleReviewClick() {
            await this.saveHomework(true);
        },
        async saveHomework(review = false) {
            this.form.lesson_id = this.lessonId;

            // костыль, чтобы не искать все места где на бэке менять код, т.к роут старый.
            if (this.form.status) {
                this.form.status = this.statuses.filter(v => v.id === this.form.status)[0];
            }

            // если указана доработка, принудительно ставим статус "Новое"
            if (review) {
                this.form.status = this.statuses.filter(v => v.id === 0)[0];
            }

            const formData = this.formPreparation();

            try {
                await this.$axios.post("api/homework/update-or-create", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                this.$toasted.success("Домашнее задание сохранено", {
                    position: "bottom-right",
                    action: {
                        text: "Закрыть",
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });

                this.$emit("on-save");
            } catch (e) {
                if (e?.response?.status === 400) {
                    this.$toasted.error("Заполните корректно все поля", {
                        position: "bottom-right",
                        action: {
                            text: "Закрыть",
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });
                } else {
                    this.$toasted.error("Попробуйте снова или обратитесь в поддержку", {
                        position: "bottom-right",
                        action: {
                            text: "Закрыть",
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });

                    console.log("Can not create or update homework: " + e);
                }
            }
        },
        closeModal() {
            this.$modal.hide("HomeworkModal");
        },
    },
};
</script>

<style lang="scss" scoped>
.textarea {
    width: 100%;
    min-height: 80px;
}
</style>