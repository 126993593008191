<template>
    <div class='calendar__modal p-4'>
        <h4 class='m-0 text-center font-weight-bold'>Удалить свободное время?</h4>
        <p class='text-center text-dark font-weight-bolder my-3'>удалить период c {{ payload.caption }} до
            {{ captionTimeEnd }}</p>
        <div class='text-center'>
            <button class='btn btn-cancel mr-1' @click="$modal.hide('FreeLessonDialog')">отмена</button>
            <button class='btn btn-danger mr-1' @click="removeTime">удалить</button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import NormalTimePicker from '../NormalTimePicker';

export default {
    name: 'FreeLessonModal',
    props: ['payload'],
    components: { VCalendar, NormalTimePicker },
    data() {
        return {
            date: { ...this.payload.data },
        };
    },
    computed: {
        captionTimeEnd() {
            return moment()
                .set('hour', this.payload.caption.split(':')[0])
                .set('minutes', this.payload.caption.split(':')[1])
                .add('minutes', 30)
                .format('HH:mm');
        },
    },
    methods: {
        async removeTime() {
            try {
                const request_id = this.$store.state.user.user_id ?? this.$store.state.user.teacher_profile.id;
                const {data} = await this.$axios.post(`/api/teacher/${request_id}/schedule/remove/time`, {
                    period_id: this.payload.data.timeId,
                    time_from: this.payload.caption
                })
                if (data) {
                    this.$toasted.success('Время удалено', { position: 'bottom-right' });
                    this.$modal.hide('FreeLessonDialog')
                }
            } catch (e) {
                alert(e)
            }
        }
    },
};
</script>

<style lang='scss' scoped>
.input-time {
    border: 1px solid #eee;
    padding: 2px 8px;
    border-radius: 2px;
}

.btn {
    width: 150px;
}

.btn-cancel {
    color: #000;
    background: #eee;
}

.calendar__header {
    margin: 0;
}

.calendar__modal_line {
    display: flex;
    align-items: center;
    padding: 20px 0;
    justify-content: center;
    flex-direction: column;
}

.calendar__modal_group {
    display: flex;
    align-items: center;
    padding-right: 10px;

    label {
        margin: 0;
        margin-right: 5px;
    }
}

.weekend-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        margin: 0;
    }

    input {
        margin-left: 3px;
        margin-right: 5px;
    }
}

.form-label {
    font-size: 14px;
    font-weight: 300;
}

.validation-error {
    color: #ec3b3b;
    font-size: 10px;
}
</style>