<template>
    <div>
        <div class='widget'>
            <h4 class='font-weight-bold'>{{$t('calendar.Мои Уроки')}}</h4>
            <div class='control-area row'>
                <div class="col-7 align-items-center d-flex">
                  <button class='btn btn-danger' :disabled='isDeleteListEmpty' v-if='deleteMode' @click='deleteFreeTime'>{{ $t('buttons.delete') }}</button>
                  <button class='btn btn-primary ml-2' v-if="oldCalendarView" :disabled='!getCellsDataMap' @click='createSchedule'>
                    {{ $t("calendar.Добавить свободное время") }}
                  </button>
                  <button class='ml-2 btn btn-secondary text-white' v-if="oldCalendarView" @click='createFreeRange'>{{ $t('calendar.Добавить свободный период') }}
                  </button>
                  <button class='ml-2 btn btn-primary' v-if="oldCalendarView" @click='addLesson' :disabled="selectedCellsDisabledBtn">{{ $t('calendar.Добавить занятие') }}</button>
                </div>
                <div class="col-5 align-items-center d-flex justify-content-end">
                  <div class='col-12 justify-content-end d-flex'>
                    <b-form-checkbox
                        @change='showClosedLesson'
                        v-if="oldCalendarView"
                        id="checkbox-view-canceled-lesson"
                        v-model="isShowCanceledLesson"
                        name="checkbox-view-canceled-lesson"
                    >
                      Показать отмененные уроки
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="oldCalendarView"
                        name="new-calendar-view"
                        class="ml-4"
                        switch
                    >
                      Старый календарь
                    </b-form-checkbox>
                  </div>
                </div>

            </div>
        </div>
        <div class='widget'>
          <!-- <TeacherCalendar/> -->
          <LessonPicker v-if="oldCalendarView" ref="lessonPicker" :teacher-id='requestId' />
          <new-lessons-calendar v-else :is-teacher="true"></new-lessons-calendar>
        </div>
        <button v-if='getCellsDataMap || deleteMode' class='btn btn-secondary text-white' @click='resetPosition'>{{ $t('buttons.reset') }}</button>
    </div>
</template>

<script>
import WeeksPicker from '@/components/WeeksPicker/WeeksPicker';
import TeacherCalendar from '../../components/Teacher/TeacherCalendar';
import LessonPicker from '../../components/Teacher/LessonPicker';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import FreeRangeModal from '../../components/Teacher/FreeRangeModal';
import CreateLessonModal from "@/components/Modal/CreateLessonModal.vue";
import moment from 'moment-timezone';
import NewLessonsCalendar from "@/components/NewLessonsCalendar/NewLessonsCalendar.vue";
import EditLessonModal from "@/components/Modal/EditLessonModal.vue";

export default {
    data() {
      return {
        isShowCanceledLesson: false,
        selectedCellsDisabledBtn: true,
        addLessonData: {
          date: null,
          from: null,
          to: null,
        },
        oldCalendarView: false,
      };
    },
    name: 'LessonsCalendar',
    components: {NewLessonsCalendar, LessonPicker, TeacherCalendar, WeeksPicker },
    computed: {
        ...mapGetters({
            selectedCells:  'scheduler/getSelectedCell',
            cellsDataMap:  'scheduler/getCellsDataMap',
            getSelectedRow: 'scheduler/getSelectedRow',
            getCellsDataMap: 'scheduler/getCellsDataMap',
            getDeleteList: 'scheduler/getDeleteList',
            isShowClosedLesson: 'scheduler/getIsShowClosedLesson'
        }),
        ...mapState({ deleteMode: state => state.scheduler.deleteMode }),
        requestId() {
            return this.$store.state.user.user_id ?? this.$store.state.user.teacher_profile.id;
        },
        isDeleteListEmpty() {
            return Object.keys(this.getDeleteList).length === 0
        }
    },
    watch: {
      selectedCells() {
        this.selectedCellsDisabledBtn = this.selectedCells.length !== 1

        if (!this.selectedCellsDisabledBtn) {
          this.addLessonData = this.cellsDataMap[this.selectedCells]


          let currentDateTime = moment().tz('Europe/Moscow')
          let selectDateTime = moment(`${this.addLessonData.date.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1')} ${this.addLessonData.from}:00`)

          // if (selectDateTime < currentDateTime) {
          //   this.selectedCellsDisabledBtn = true
          // }

        }
        else {
          this.addLessonData = {
            date: null,
            from: null,
            to: null,
          }
        }
      }
    },
    methods: {
        ...mapMutations({
            resetPosition: 'scheduler/resetPosition',
            setFilterByKey: 'lessonsSearch/setFilterByKey'
        }),
        ...mapActions({
            fetchTeachersOptions: 'teacher/fetchTeachersOptions',
            addSchedule: 'scheduler/addSchedule',
            removeFreeTime: 'scheduler/deleteFreeTime',
            fetchLessonsSchedule: 'lessons/fetchLessonsSchedule',
            createTeacherLesson: 'lessons/createTeacherLesson',
            setScheduleRefresh: 'scheduler/scheduleRefresh',
            showClosedLesson: 'scheduler/showClosedLesson',
            loadLessons: 'lessonsSearch/loadLessons',

        }),
        async deleteFreeTime() {
            const res = await this.removeFreeTime({ requestId: this.requestId });
            if (!res.success) {
                this.$toasted.error(res, { position: 'bottom-right' });
            } else {
                this.$toasted.success(this.$t('toasted.Время удалено'), { position: 'bottom-right' });
            }
        },
        async createSchedule() {
            const res = await this.addSchedule({ requestId: this.requestId });
            if (!res.success) {
                this.$toasted.error(res, { position: 'bottom-right' });
            } else {
                this.$toasted.success(this.$t('toasted.Время добавлено'), { position: 'bottom-right' });
            }
        },
        createFreeRange() {
            this.$modal.show(FreeRangeModal, {
                teacher: true,
                actionHandler: this.handleUpdateAction,
            }, {
                name: 'LessonCalendarModal',
                width: '400px',
                height: 'auto',
                scrollable: false,
            });
        },
        addLesson() {
          this.$modal.show(
              CreateLessonModal,
              {
                teacher: true,
                lessonDatePrepared: this.addLessonData.date,
                lessonTimePrepared: this.addLessonData.from,
                  beforeCreatedAction:  this.handleCreateAction
              },
              {
                height: 'auto',
                maxHeight: 900,
                scrollable: true,
              },
          );
        },
        loadTeacherOptions() {
          return this.fetchTeachersOptions({
            gender: this.gender,
            language: this.language,
          });
        },
        handleUpdateAction() {
            // TODO сделать обновление календаря
        },
        handleCreateAction() {
            // TODO сделать обновление календаря
        },
    },
  async created() {
    await this.loadTeacherOptions();
    this.setFilterByKey({key: 'date_from', value: moment(this.currentDate).startOf('week').format('YYYY-MM-DD')});
    this.setFilterByKey({key: 'date_to', value: moment(this.currentDate).endOf('week').format('YYYY-MM-DD')});
    this.setFilterByKey({key: 'teachers_ids', value: [this.requestId]});
    await this.loadLessons();
  },
};
</script>

<style scoped lang='scss'>

</style>