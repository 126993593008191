<template>
    <div class='data-cell'
         :class='{"select_promo": lesson.promo_lesson, "select_lesson": !lesson.promo_lesson}'
         v-b-tooltip.hover
         :title='tooltip'
    >
        <div class='day-data'>
            <a class='name' @click.prevent='showItemData'>
                {{ lesson.details.student.full_name }}
            </a>

            <span class='status' :class='classMap[lesson.details.status]'>
                <i class='fa fa-dot-circle-o' aria-hidden='true' v-if='lesson.details.status === 0'></i>
                <i class='fa fa-check' aria-hidden='true' v-if='lesson.details.status === 1'></i>
                <i class='fa fa-circle-o' aria-hidden='true' v-if='lesson.details.status === 2'></i>
                <i class='fa fa-times' aria-hidden='true' v-if='lesson.details.status === 3'></i>
            </span>

            <a
                v-if="lesson.details.homework && lesson.details.homework.status === 1"
                href="#"
                class="task"
                @click.prevent="openHomework"
            >
                <i class="fa fas fa-tasks"></i>
            </a>
        </div>
    </div>
</template>

<script>
import DayDataModal from './DayDataModal';
import HomeworkModal from "@/components/Modal/HomeworkModal";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'DataCell',
    props: ['lesson'],
    data() {
        return {
            // const STATUS_PLANNED = 0;
            // const STATUS_FINISHED = 1;
            // const STATUS_SKIPPED = 2;
            // const STATUS_CANCELED = 3;
            classMap: {
                0: 'status__planned',
                1: 'status__finished',
                2: 'status__skipped',
                3: 'status__canceled',
            },
        };
    },
    computed: {
        ...mapGetters({
          getLessonsSchedule: 'lessons/getLessonsSchedule',
        }),
        tooltip() {
            const from = this.lesson.details.from + ' - ' + this.lesson.details.to;
            const status = '\n' + this.lesson.details.status_title;
            return from + status;
        },
    },
    methods: {
        ...mapActions({
          setScheduleRefresh: 'scheduler/scheduleRefresh',
        }),
        showItemData() {
          this.$modal.show(DayDataModal, {
            lessonId: this.lesson.details.lesson_id,
          }, {
            name: 'DayDataModal',
            width: '20%',
            height: 'auto',
            scrollable: true
          }, {
            'before-close': () => {this.setScheduleRefresh()}
          });
        },

        openHomework() {
            this.$modal.show(
                HomeworkModal,
                {
                    lesson: this.lesson.details
                },
                {
                    name: 'HomeworkModal',
                    width: '80%',
                    height: 'auto',
                    scrollable: true,
                }
            );
        },
    },
};
</script>

<style lang='scss' scoped>
.status {
    position: absolute;
    bottom: 10px;
    right: 6px;
    //font-size: 10px;
    border-radius: 50%;
    width: 10px;
    height: 10px;

    //&__canceled {
    //    background-color: #f15e5e;
    //}

    //&__skipped {
    //    background-color: #f1915e;
    //}

    &__finished {
        //i {
        //    width: 100%;
        //    height: 100%;
        //}

        //background-color: $free_col;
    }

    //&__{
    //    background-color: #A4B2E8FF;
    //}
}

.data-cell {
    @include cell;
    position: relative;
    overflow: hidden;
}

.day {
    &-data {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background: #fff;
        //position: absolute;
        margin-top: 5px;
        margin-bottom: 1px;
        box-sizing: content-box;
        //bottom: 5px;
        transition: .1s;

        //&:hover {
        //    background-color: #eee;
        //}

        .name {
            color: #4d4d4d;
            font-weight: 600;
            font-size: 10px;
            text-align: center;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.select_promo {
    background: $promo_col;
}
.select_lesson {
    background-color: $picked_col;
}

.task {
    position: absolute;
    bottom: 10px;
    left: 6px;
    width: 10px;
    height: 10px;
}
</style>