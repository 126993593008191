<template>
    <div class='calendar__modal p-4'>
        {{ showBusyInput }}
        <h3 class='calendar__header'>
            Добавить расписание на {{ displayedDate }}
        </h3>
        <div class='calendar__modal_line flex-row'>
            <div class='calendar__modal_group'>
                <label for='free'>Свободен</label>
                <input id='free' v-model='type' name='type' type='radio' value='free'>
            </div>
            <div class='calendar__modal_group'>
                <label for='busyUntil'>Свободен c</label>
                <input id='busyUntil' v-model='type' name='type' type='radio' value='busyUntil'>
            </div>
        </div>


        <form class='calendar__modal_line pt-0' v-if='showBusyInput' @submit.prevent='sendFormData'>
            <div class='calendar__modal_group'>
                <VCalendar v-model='calendarRange' is-range :min-date='new Date()' />

            </div>
            <p class='p-2 m-0 validation-error' v-if='!validated'>*обязательно для заполнения</p>
            <div class='mt-4 weekend-container flex-column'>
                <p class='p-2 m-0'>Время:</p>
                <div class='d-flex justify-content-between w-100'>
                    <div class='d-flex flex-column'>
                        <label class='form-label'>С:</label>
                        <NormalTimePicker v-model='time_from' id='original_lessontime' />
                        <p class='p-2 m-0 validation-error' v-if='!validated'>*обязательно для заполнения</p>
                    </div>
                    <div class='d-flex flex-column'>
                        <label class='form-label'>До:</label>
                        <NormalTimePicker v-model='time_to' id='original_lessontime' />
                        <p class='p-2 m-0 validation-error' v-if='!validated'>*обязательно для заполнения</p>
                    </div>
                </div>

            </div>
            <div class='weekend-container justify-content-between'>
                <p class='p-2 m-0'>Включая:</p>
                <div>
                    <label for='saturday'>Субботу</label>
                    <input :disabled='weekendPicked && onlyWeekendMode' id='saturday' type='checkbox' v-model='saturday'
                           class='' />
                    <label for='sunday'>Воскресенье</label>
                    <input :disabled='weekendPicked && onlyWeekendMode' id='sunday' type='checkbox' v-model='sunday'
                           class='' />
                </div>
            </div>
            <div class='weekend-container justify-content-between'>
                <p class='p-2 m-0'>Только в:</p>
                <div>
                    <label for='saturdayonly'>Субботу</label>
                    <input :disabled='weekendPicked && !onlyWeekendMode' id='saturdayonly' type='checkbox'
                           v-model='saturdayOnly' class='' />
                    <label for='sundayonly'>Воскресенье</label>
                    <input :disabled='weekendPicked && !onlyWeekendMode' id='sundayonly' type='checkbox'
                           v-model='sundayOnly' class='' />
                </div>
            </div>
        </form>

        <div class='text-center'>
            <button class='btn btn-cancel mr-1' @click="$modal.hide('LessonCalendarModal')">отмена</button>
            <button :disabled='!validated' type='submit' class='btn btn-primary' @click='sendFormData'>сохранить
            </button>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import NormalTimePicker from '../NormalTimePicker';

export default {
    name: 'LessonCalendarModal',
    props: ['payload'],
    components: { VCalendar, NormalTimePicker },
    data() {
        return {
            type: null,
            busyInput: null,
            calendarRange: {
                start: null,
                end: null
            },
            saturday: false,
            saturdayOnly: false,
            sunday: false,
            sundayOnly: false,
            time_from: null,
            time_to: null,
        };
    },
    computed: {
        validated() {
            return this.showBusyInput ? (this.calendarRange.start && this.calendarRange.end) && this.time_from && this.time_to : this.type === 'free';
        },
        onlyWeekendMode() {
            return this.saturdayOnly || this.sundayOnly;
        },
        weekendPicked() {
            return this.saturday || this.saturdayOnly || this.sunday || this.sundayOnly;
        },
        payloadTime() {
            return {
                from: this.payload.timePeriod[0],
                to: this.payload.timePeriod[1],
            };
        },
        formattedDate() {
            const [day, month, year] = this.payload.date[3].split('.');
            moment.locale('ru');
            return new moment(month + '.' + day + '.' + year);
        },
        displayedDate() {
            return new moment(this.formattedDate).locale('ru').format('dddd DD MMMM YYYY');
        },
        showBusyInput() {
            return this.type === 'busyUntil';
        },
        busyType() {
            // 1 - Включая Субботу
            // 2 - Включая Восскресенье
            // 3 - Все дни
            // 4 - Исключая выходные
            // 5 - Только по выходным
            // 6 - Только по субботам
            // 7 - Только по воскресеньям
            // 0 - Выбор из диапазона

            if (this.saturday && this.sunday) {
                return 3;
            }

            if (this.saturday) {
                return 1;
            }

            if (this.sunday) {
                return 2;
            }

            if (this.saturdayOnly && this.sundayOnly) {
                return 5;
            }

            if (this.saturdayOnly) {
                return 6;
            }

            if (this.sundayOnly) {
                return 7;
            }

            if (this.showBusyInput) {
                return 4;
            }

            return 0;
        },
    },
    methods: {
        async sendFormData() {
            try {
                const request_id = this.$store.state.user.user_id ?? this.$store.state.user.teacher_profile.id;
                const payload_date = this.showBusyInput ? this.calendarRange.start : this.formattedDate;
                const { data } = await this.$axios.post(`/api/teacher/${request_id}/schedule/add`, {
                    date: moment(payload_date).format('DD.MM.YYYY'),
                    date_to: this.showBusyInput && this.calendarRange.end ? moment(this.calendarRange.end).format('DD.MM.YYYY'): null,
                    from: this.showBusyInput ? this.time_from : this.payloadTime.from,
                    to: this.showBusyInput ? this.time_to : this.payloadTime.to,
                    // date_range: this.showBusyInput,
                });
                if (data && data.success) {
                    this.$toasted.success('Успешно', { position: 'bottom-right' });
                    this.$modal.hide('LessonCalendarModal')
                    this.$emit('scheduleChanged');
                }
            } catch (e) {
                this.$toasted.error(e, { position: 'bottom-right' });
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.input-time {
    border: 1px solid #eee;
    padding: 2px 8px;
    border-radius: 2px;
}

.btn {
    width: 150px;
}

.btn-cancel {
    color: #000;
    background: #eee;
}

.calendar__header {
    margin: 0;
}

.calendar__modal_line {
    display: flex;
    align-items: center;
    padding: 20px 0;
    justify-content: center;
    flex-direction: column;
}

.calendar__modal_group {
    display: flex;
    align-items: center;
    padding-right: 10px;

    label {
        margin: 0;
        margin-right: 5px;
    }
}

.weekend-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        margin: 0;
    }

    input {
        margin-left: 3px;
        margin-right: 5px;
    }
}

.form-label {
    font-size: 14px;
    font-weight: 300;
}

.validation-error {
    color: #ec3b3b;
    font-size: 10px;
}
</style>