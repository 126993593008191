import { serialize } from '@/utils/object-to-form-data';

export const formPreparation = {
    methods: {
        formPreparation(allowEmptyArrays = false) {
            const form = new FormData();
            const options = {
                /**
                 * добавляет индексы массивам
                 * defaults to false
                 */
                indices: true,

                /**
                 * treat null values like undefined values and ignore them
                 * defaults to false
                 */
                nullsAsUndefineds: true,

                /**
                 * convert true or false to 1 or 0 respectively
                 * defaults to false
                 */
                booleansAsIntegers: true,

                /**
                 * store arrays even if they're empty
                 * defaults to false
                 */
                allowEmptyArrays: allowEmptyArrays,
            };

            const formDataFormatted = serialize(this.form, options, form);

            return formDataFormatted;
        },
    },
};
