<template>
    <div
        class='day-time-empty'
        :class='{"can-pick": isReadyToPick, "hovered": isInSelectedRange}'
        @click='selectCell'
    ><input class='checkbox' type='checkbox' v-model='selected' v-if='isReadyToPick'></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'EmptyCell',
    props: ['cell', 'row', 'date', 'cellData'],
    data() {
        return {
            selected: false,
        }
    },
    computed: {
        ...mapGetters({ getSelectedRow: 'scheduler/getSelectedRow', getCellsDataMap: 'scheduler/getCellsDataMap'}),
        isReadyToPick() {
            return this.getCellsDataMap
        },
        formattedDate() {
            const date = moment().set('hour', this.cellData.caption.split(':')[0]).set('minute', this.cellData.caption.split(':')[1])
            return {
                from: date.format('HH:mm'),
                to: date.add('minute', 30).format('HH:mm'),
                date: this.date
            }
        },
        isInSelectedRange() {
            const cells = this.$store.state.scheduler.selectedCells;

            const cond = cells.includes(this.row + "-" + this.cell);
            this.setSelect(cond)
            // const cellsCond = (cells.from <= this.cell && this.cell <= cells.to) || (cells.from >= this.cell && this.cell >= cells.to)
            // // return cellsCond && this.getSelectedRow === this.row
            // // console.log('000000000000000000', cells.has(this.cell));
            // return cellsCond && this.row === this.getSelectedRow;
            return cond;
        },
    },
    methods: {
        ...mapActions({
            selectEmptyCell: 'scheduler/selectEmptyCell',
            hoverEmptyCell: 'scheduler/hoverEmptyCell'
        }),
        setSelect(val) {
            this.selected = val
        },
        selectCell() {
            this.selectEmptyCell({
                row: this.row,
                cell: this.cell,
                cellData: this.formattedDate,
                date: this.date
            });
        },
    },
};
</script>

<style lang='scss' scoped>
.can-pick {
    background: darken($na_col, 9) !important;
}

.hovered {
    background: $select_col !important;
}

.checkbox {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: none;
    outline: none;
}

.day {
    &-table {
        //width: 100%;
        //border-collapse: separate;
    }

    &-row {
        display: flex;
    }

    &-wrap {
        height: 55px;
    }

    &-time {
        @include cell;
        overflow: hidden;

        &-empty {
            transition: .05s;
            background: #f3f3f3;
            @include cell;
            position: relative;

            &:hover {
                background-color: #eee;
            }
        }

    }
}
</style>