<template>
    <div>
        <Preloader v-if='loader' />
        <h4 class=''>Список учеников</h4>
        <p class='p-0 mb-4'>Синхронизация производится в автоматическом режиме, ежедневно с 03:00 до 03:20 по Московскому времени</p>
        <div class='widget'>
            <div class='filter__container'>
                <div class='row mb-3 justify-content-between'>
                    <div class='input-group col-4 d-flex'>
                        <input type='search' class='form-control custom__search-input mr-3' @keyup='searchByEnter' v-model='searchWord'
                               id='search-input' placeholder="Поиск...">
                        <span class='input-group-append'>
                            <button type='button' class='btn btn-default custom__search-btn' @click='search'>Искать</button>
                        </span>
                    </div>
                    <div class='col-4 text-right'>
<!--                        <button class='button_add custom__add-student-btn' @click='addData'>Добавить ученика</button>-->
                        <!--            <button class="button_sync ml-3" @click="syncronizeData">Синхронизировать</button>-->
                    </div>
                </div>
                <div class='input-group col-4'>

                </div>
            </div>
            <table class='table table-hover'>
                <thead class='table_head'>
                <tr>
                    <th>ФИО ученика</th>
                    <th>Возраст</th>
                    <th>Уровень</th>
                    <th>ФИО родителя</th>
                    <th>Телефон родителей</th>
                    <th>Уведомления</th>
                    <th>Часовой пояс</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='student in students' :key='student.id'>
                    <td class="student__name">
                        {{ student.full_name }}<span
                        v-if='student.duplicate_id && student.duplicate_id > 0' title='есть дубликат' class='student__badge position-relative top-0'><img src='@/assets/icons/duplicate.svg'
                                                                                                        alt='' height='20px' width='20px'></span>


                    </td>
                    <td>{{ student.age }}</td>
                    <td>{{ student.experience_title }}</td>
                    <td>{{ student.parent_name }}</td>
                    <td>{{ student.parent_phone }}</td>
                    <td>{{ student.notifications_way }}</td>
                    <td>{{ student.timezone }}</td>
                    <td class='actions_container'>
                      <router-link class='leads_table_action_link btn mr-2 btn-primary'
                                   :to="{name: 'ShowStudent', params: {id: student.id}}">
                        <span class='glyphicon glyphicon-eye-open' />
                      </router-link>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class='overflow-auto'>
            <b-pagination
                v-if="getPageMeta && getPageMeta.total > 1"
                class="paginator"
                :total-rows="getPageMeta.total"
                :per-page="getPageMeta.per_page"
                aria-controls="my-table"
                v-model="currentPage"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DeleteModal from '@/components/Modal/DeleteModal';
import Preloader from '@/components/Preloader';
import VueMultiselect from 'vue-multiselect';
import dayjs from "dayjs";

export default {
    name: 'Students',
    components: { Preloader, VueMultiselect},
    data() {
        return {
            loader: false,
            routerParams: { ...this.$route.query },
            searchWord: this.$route.query.search || '',
            currentPage: this.$route.query.page || 1,
            statusOptions: [
              { value: '0', text: 'Саморегистрация' },
              { value: '1', text: 'Занимается' },
              { value: '2', text: 'Завершил обучение' },
              { value: '3', text: 'Промо урок' },
            ],
            selectedStatus: [],
            students: [],
        };
    },
    computed: {
        ...mapGetters({
            userId: 'user/getUserId',
            getPageMeta: 'students/getPageMeta',
        }),
    },
    watch: {
        searchWord(newVal, oldVal) {
          if (newVal === null || newVal === '') {
            this.search();
          }
        },
        currentPage(value) {
            this.routerParams['page'] = value;
            this.updateRouter();
            this.loadStudentList(this.$route.query);
        },
    },
    methods: {
        async loadStudentList(query) {
          const { data } = await this.$axios.get(`/crm/teachers/${this.userId}/students`, {
            params: {
              ...query
            }
          })
          this.students = data.data
        },
        ...mapActions({
            findStudents: 'students/findStudents',
            deleteRecord: 'students/deleteRecord',
        }),
        deleteRow(id, name) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: this.deleteRecord,
                }, { name: 'DeleteModal' },
            );
        },
        addData(e) {
            e.preventDefault();
            this.$router.push({ name: 'StudentAdd' });
        },
        async searchByEnter(e) {
            if (this.searchWord.length > 1) {
                if (e.keyCode == 13) {
                    await this.search()
                }
            }
        },
        toggleLoader() {
            this.loader = !this.loader;
        },
        updateRouter() {
          this.$router.push({ name: 'StudentList', query: this.routerParams });
        },
        async search() {
          const query = Object.assign({}, this.$route.query, { search: this.searchWord});
          await this.$router.push({query});
          await this.loadStudentList(query);
        }
    },
    created() {
        this.loadStudentList(this.$route.query);
    },
};
</script>

<style lang='scss' scoped>
.btn .glyphicon {
    top: 0 !important;
}


.button_add {
    display: inline-block;
    vertical-align: middle;
    border: none;
    font-weight: 400;
    background: #3E86CA;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0 20px;
    font-size: 14px;
    height: 100%;
    transition: .1s;

    &:hover {
        background: #2e6ca7;
        text-decoration: none;
    }
}

.actions_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_sync {
    @extend .button_add;
    background: #4eb76e;

    &:hover {
        background: darken(#4eb76e, 10);
    }
}

.custom__multiselect {
  width: 50%;

}

.custom__search-input,
.custom__search-btn,
.custom__add-student-btn{
  height: 43px;
}

.student {
    &__name {
        position: relative;
    }

    &__badge {
        //position: absolute;
        //top: 30px;

        img {
            width: 30px;
        }
    }
}
</style>