<template>
    <div class='calendar__modal p-4'>
        <h4 class='m-0 font-weight-bold'>{{$t('calendar.Информация об уроке')}}</h4>
        <p v-if='form.date_from && form.date_to' class='font-weight-bold mb-0'>{{ $t('Время') }}: {{ lessonDateFrom }} - {{ lessonDateTo }}</p>
        <p v-if='form.lesson_type' class='mb-0'>{{ lessonType }}</p>
        <div v-if='form.student' class='data-details mt-3'>
            <div class='data-group'>
                <span>{{$t('calendar.Студент')}}:</span>
                <p>{{ studentData.full_name }}</p>
            </div>
            <div class='data-group' v-if='!this.form.lesson_type'>
                <span>{{$t('calendar.Возраст')}}:</span>
                <p>{{ studentData.age }}</p>
            </div>
            <div class='data-group' v-if='!this.form.lesson_type'>
                <span>{{$t('calendar.Логин игровой платформы')}}:</span>
                <p>{{ studentData.lichess_username || 'отсутствует' }}</p>
            </div>
            <div class='data-group' v-else>
                <span>{{$t('calendar.Опыт')}}:</span>
                <p>{{ getExperienceTitle }}</p>
            </div>
            <div class='data-group mb-4' v-if='!this.form.lesson_type'>
                <span>{{$t('calendar.Контакты родителя')}}:</span>
                <p class='mb-0'>{{ studentData.parent_phone }}</p>
                <p class='mb-0' v-if='studentData.parent_name'>{{ studentData.parent_name }}</p>
                <p class='mb-0'>{{ studentData.parent_email }}</p>
            </div>
            <div class='data-group mb-4' v-else>
                <span v-if='studentData.parent_name'>{{$t('calendar.Контакты родителя')}}:</span>
                <p class='mb-0' v-if='studentData.parent_name'>{{ studentData.parent_name }}</p>
            </div>
        </div>

        <div class='data-group'>
            <label for="input-room-url" class='mb-0'>Ссылка на комнату:</label>
            <p>{{ form.room_url ? form.room_url : '-' }}</p>
        </div>

        <div class='data-group'>
            <span>{{$t('calendar.Статус')}}</span>
            <p class='d-flex justify-content-between'>{{ getStatusTitle }}</p>
        </div>
        <div class='data-group mb-4'>
          <a class='text-blue-600 font-weight-bold'
              @click="openHomework(form)">
            {{$t('calendar.to_homework')}}
          </a>
        </div>
        <div class='text-center'>
            <button class='btn btn-cancel mr-3' @click="$emit('close')">{{ $t('buttons.close') }}</button>
            <button class='btn btn-primary' v-if="!form.lesson_type" @click="editLesson">Редактировать</button>
        </div>
    </div>
</template>

<script>
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import NormalTimePicker from '../NormalTimePicker';
import {mapGetters, mapActions} from 'vuex'
import HomeworkModal from '@/components/Modal/HomeworkModal';
import EditLessonModal from "@/components/Modal/EditLessonModal.vue";
import moment from "moment/moment";

export default {
    name: 'DayDataModal',
    props: {
      lessonId: {
        type: Number,
        required: true
      }
    },
    components: { VCalendar, NormalTimePicker },
    data() {
        return {
            form: {
              date_from: null,
              date_to: null,
              student: {
                experience: null
              }
            },
            // date: { ...this.payload.data },
            status: null,
            note: null,
            editMode: false,
            room_url: null,
            errors: null,
        };
    },
    computed: {
        ...mapGetters({
            getLessonStatusList: 'common/getLessonStatusList',
            getExperienceList: 'common/getExperienceList',
        }),
        studentData() {
          return this.form?.student
        },
        lessonType() {
            return this.form.lesson_type ? 'Пробное занятие' : 'Обычное занятие'
        },
        lessonDateFrom: function () {
          return moment(this.form.date_from).format('HH:mm')
        },
        lessonDateTo: function () {
          return moment(this.form.date_to).format('HH:mm')
        },
        getStatusTitle: function () {
          for (let item of this.getLessonStatusList) {
            if (this.form.student.experience === item.value)
              return item.text
          }
          return 'Не определенно'
        },
        getExperienceTitle: function () {
          for (let item of this.getExperienceList) {
            if (this.form.student.experience === item.value)
              return item.text
          }
          return 'Не определенно'
        },
    },
    methods: {
        ...mapActions({
          fetchSchedule: 'scheduler/fetchSchedule',
          updateTeacherLessonDetails: 'lessons/updateTeacherLessonDetails',
          setScheduleRefresh: 'scheduler/scheduleRefresh',
        }),
        async fetchLessonDetail() {
          try {
            let response = await this.$axios.post(`/api/teacher/lesson/${this.lessonId}`, {
              lesson_id: this.lessonId
            });
            this.form = response.data
            this.status = this.form.status
            this.note = this.form.note
            this.room_url = this.form.room_url
          } catch (e) {
            let errMsg = `Ошибка загрузки занятия.`;
            if (e.response && e.response.data.error) {
              errMsg = e.response.data.error;
            }
            this.showErrorNotify(errMsg)
            throw e;
          }
        },
        editLesson() {
          this.$modal.show(
              EditLessonModal,
              {
                teacher: true,
                lessonId: this.lessonId,
                  actionHandler: this.handleUpdateAction,
                  removeHandler: this.handleRemoveAction,
              },
              {
                height: 'auto',
                maxHeight: 900,
                scrollable: true,
              },
          );
        },
        async handleModalDone() {
            await this.fetchSchedule(this.fetchPayload);
            this.buildDataCalendar();
            this.resetPosition();
        },
        openHomework(lesson) {
            this.$modal.show(
                HomeworkModal,
                {
                  lesson
                },
                {
                  name: 'HomeworkModal',
                  width: '80%',
                  height: 'auto',
                  scrollable: true,
                }
            );
        },
        async handleUpdateAction(data) {
            await this.loadLessons();
        },
        async handleCreateAction(data) {
            await this.loadLessons();
        },
        async handleRemoveAction(data) {
            await this.loadLessons();
        },
    },
    async created() {
        await this.fetchLessonDetail()
    }
};
</script>

<style lang='scss' scoped>
.input-time {
    border: 1px solid #eee;
    padding: 2px 8px;
    border-radius: 2px;
}

.calendar__modal {
    border-radius: 15px;
}

.btn {
    width: 150px;
}

.btn-cancel {
    color: #000;
    background: #eee;
}

.calendar__header {
    margin: 0;
}

.calendar__modal_line {
    display: flex;
    align-items: center;
    padding: 20px 0;
    justify-content: center;
    flex-direction: column;
}

.calendar__modal_group {
    display: flex;
    align-items: center;
    padding-right: 10px;

    label {
        margin: 0;
        margin-right: 5px;
    }
}

.weekend-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        margin: 0;
    }

    input {
        margin-left: 3px;
        margin-right: 5px;
    }
}

.form-label {
    font-size: 14px;
    font-weight: 300;
}

.validation-error {
    color: #ec3b3b;
    font-size: 10px;
}
.data-group {
    span, label {
        font-size: 12px;
    }
    p {
        font-weight: 500;
    }
}
</style>