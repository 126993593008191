<template>
    <div class='selected_free' @click='selectForDelete'>
        <div class='action-layer'>
            <input type='checkbox' v-if='deleteMode' v-model='selected' id=''>
        </div>
    </div>
</template>

<script>
import FreeLessonModal from './FreeLessonModal';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'FreeCell',
    props: ['date', 'cell', 'row', 'cellData'],
    data() {
        return {
            selected: false,
        };
    },
    computed: {
        ...mapGetters({ getDeleteList: 'scheduler/getDeleteList' }),
        ...mapState({ deleteMode: state => state.scheduler.deleteMode }),
        isSelected() {
            const key = this.row + '-' + this.cell;
            const res = this.getDeleteList[key];
            this.toggleSelected(res);
            return res;
        },
    },
    methods: {
        ...mapActions({ fetchSchedule: 'scheduler/fetchSchedule', setDeleteList: 'scheduler/selectForDelete' }),
        toggleSelected(payload) {
            this.selected = payload;
        },
        openFreeLessonDialog() {
            if (this.managerMode) {
                return;
            }
            this.$modal.show(FreeLessonModal, {
                payload: this.cellData,
            }, { name: 'FreeLessonDialog', width: '20%', height: 'auto', scrollable: true }, {
                'before-close': this.handleModalDone,
            });
        },
        async selectForDelete() {
            this.selected = !this.selected;
            await this.setDeleteList({
                cell: this.cell,
                row: this.row,
                date: this.date,
                data: this.cellData,
            });
        },
        async handleModalDone() {
            await this.fetchSchedule({});
        },
    },
};
</script>

<style lang='scss' scoped>

.selected_free {
    @include cell;
    border: none;
    background: $free_col;
    color: #fff;
    position: relative;

    &:hover {
        background: darken($free_col, 10);
    }

    &.active {
        background: darken($free_col, 10);
    }
}

.action-layer {
    position: absolute;
    bottom: 5px;
    right: 5px;
    //width: 100%;
    //height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>