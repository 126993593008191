<template>
    <div>
        <div class='lesson__picker'>
            <div class='table__header'>
                <div class='header__hour' style="width: '100%';" v-for='(item, idx) in tableHeader' :key='idx'>
                    <span>{{ item }}</span>
                </div>
            </div>
            <div class='d-flex position-relative' style='width: 5144px;'>
                <div class='days__container'>
                    <div class='d-flex flex-column align-content-between h-100'>
                        <div class='day__wrap' v-for='(day, idx) in tableDays' :key='idx'>
                            <div class='day__block'>
                                 <span class='day day_name'>
                                    {{ day[0] }}
                                </span>
                                <span class='day day_date'>
                                    {{ day[1] }}
                                </span>
                                <span class='day day_month'>
                                      {{ day[2] }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class='week-area'>
                    <div
                        class='day-row'
                        v-for='(week, idx) in daysMatrix' :key='idx'
                    >
                        <template>
                            <LessonDay
                                v-for='(day, ind) in week'
                                :rowIdx='idx + 1'
                                :cellIdx='ind + 1'
                                :key='ind'
                                :day='day'
                                :week='week'
                                :date='tableDays[idx][3]'
                                :ghostPick='ghostPick'
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class='d-flex mt-2'>
            <div class='control'>
                <div class='control-item'>
                    <a class='control-btn control-btn__left' @click.prevent='prevWeek'><i class='fa fa-arrow-left'
                                                                                          aria-hidden='true'></i></a>
                    <a class='control-btn control-btn__left' @click.prevent='nextWeek'><i class='fa fa-arrow-right'
                                                                                          aria-hidden='true'></i></a>
                </div>
            </div>
            <div class='legend'>
                <div class='legend__item'>
                    <div class='legend__col legend__col_busy'></div>
                    <div class='legend__descr'>{{ $t('calendar.legend.busyTime') }}</div>
                </div>


                <div class='legend__item'>
                    <div class='legend__col legend__col_free'></div>
                    <div class='legend__descr'>{{ $t('calendar.legend.freeTime') }}</div>
                </div>

                <div class='legend__item'>
                    <div class='legend__col legend__col_promo'></div>
                    <div class='legend__descr'>{{ $t('calendar.legend.promoLes') }}</div>
                </div>

                <div class='legend__item'>
                    <div class='legend__col legend__col_picked'></div>
                    <div class='legend__descr'>{{ $t('calendar.legend.hasLes') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import LessonCalendarModal from './LessonCalendarModal';
import FreeLessonModal from './FreeLessonModal';
import DayDataModal from './DayDataModal';
import LessonDay from './LessonDay';

export default {
    name: 'LessonPicker',
    components: { LessonDay },
    props: {
        teacherId: {
            type: [Number, Array],
            required: true,
        },
        managerMode: {
            type: Boolean,
        },
    },
    data() {
        return {
            lessonPeriod: 0.5, // часов
            dayPeriod: 7, // дней
            tableHeader: null,
            tableDays: null,
            daysMatrix: [],
            selectMatrix: [],
            selectedCells: [],
            selectType: null,
            currentPick: {          // текущий выбор клетки (для ивентов)
                from: null,           // от [номер_строки, номер_ячейки] - чтобы достучаться до daysMatrix
                to: null,              // до [номер_строки, номер_ячейки] - нужно для номера ячейки и номера строки вычесть единицу
            },
            ghostPick: {            // текущая позиция (для ховера и т.д)
                from: null,           // от [номер_строки, номер_ячейки] - чтобы достучаться до daysMatrix
                to: null,              // до [номер_строки, номер_ячейки] - нужно для номера ячейки и номера строки вычесть единицу
            },
            nearMaxCoord: null,
            dayPick: false,
            nearMaxIndex: null,
            nearMinIndex: null,
            hoveredCells: [],
            isModalActive: false,
            modalPayload: null,
            dayStartTime: 0,
            dayEndTime: 24,
            dateFrom: null,
            dateTo: null,
        };
    },
    computed: {
        ...mapGetters({
            getScheduleRefresh: 'scheduler/getScheduleRefresh',
            getLessonsSchedule: 'lessons/getLessonsSchedule',
            getAvailableDaysShedule: 'lessons/getAvailableDaysShedule',
            getDayHoursRange: 'lessons/getDayHoursRange',
            getSchedule: 'scheduler/getSchedule',
            getFreeTime: 'scheduler/getFreeTime',
            isShowClosedLesson: 'scheduler/getIsShowClosedLesson'
        }),
        fetchPayload() {
            return {
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                requestId: this.teacherId,
                locale: this.$i18n.locale,
            };
        },
        periodLessonsData() {
            return this.getSchedule;
        },
        freeTime() {
            return this.getFreeTime;
        },
    },
    watch: {
        periodLessonsData() {
            this.buildDataCalendar();
        },
        getScheduleRefresh() {
          if (this.getScheduleRefresh) {
            this.fetchSchedule(this.fetchPayload);
            this.setScheduleRefresh()
          }
        },
        isShowClosedLesson() {
          this.buildDataCalendar()
        }
    },
    methods: {
        ...mapActions({
            fetchLessonsSchedule: 'lessons/fetchLessonsSchedule',
            fetchSchedule: 'scheduler/fetchSchedule',
            setScheduleRefresh: 'scheduler/scheduleRefresh',
        }),
        async prevWeek() {
            this.dateFrom = (new moment(this.dateFrom)).subtract(1, 'week');
            this.dateTo = (new moment(this.dateFrom)).endOf('week');
            await this.fetchSchedule(this.fetchPayload).then(() => {
                this.fillDays(this.dateFrom, this.dateTo);
                this.buildDataCalendar();
            });
        },
        async nextWeek() {
            this.dateFrom = (new moment(this.dateFrom)).add(1, 'week');
            this.dateTo = (new moment(this.dateFrom)).endOf('week');
            await this.fetchSchedule(this.fetchPayload);
            this.fillDays(this.dateFrom, this.dateTo);
            this.buildDataCalendar();
        },


        isCellInAllowedRange(pos) {

        },

        getTargetCoords(evt) {
            return [parseInt(evt.target.dataset.row), parseInt(evt.target.dataset.cell)];
        },
        /**
         * Функция принимает на вход ивент в котором достает координаты из data аотрибута и сохраняет их в currentPick и ghostPick
         * при повторном ивенте меняет флаг действия на противоположный и
         * */
        openFreeLessonDialog(payload) {
            if (this.managerMode) {
                return;
            }
            this.$modal.show(FreeLessonModal, {
                payload,
            }, { name: 'FreeLessonDialog', width: '20%', height: 'auto', scrollable: true }, {
                'before-close': this.handleModalDone,
            });
        },
        resetPosition() {
            this.ghostPick = {
                from: null,
                to: null,
            };
        },
        savePeriod() {
            this.resetPosition();
            this.$modal.hideAll();
        },
        openDialog(coords) {
            const payload = {
                date: this.tableDays[this.currentPick.from[0] - 1],
                timePeriod: this.dateFromCellPosition(),
                saveFunction: this.savePeriod,
            };
            this.$modal.show(LessonCalendarModal, {
                payload,
            }, { name: 'LessonCalendarModal', width: '20%', height: 'auto', scrollable: true }, {
                'before-close': this.handleModalDone,
            });
        },

        async handleModalDone() {
            await this.fetchSchedule(this.fetchPayload);
            this.buildDataCalendar();
            this.resetPosition();
        },
        handleSelectDone() {
            const row = this.$store.state.scheduler.selectedRow;
            const cells = this.$store.state.scheduler.selectedCells;
            console.log('SELECT DONE', row, cells);

        },
        dateFromCellPosition() {
            const date = this.tableDays[this.currentPick.from[0] - 1];
            const dates = [
                this.dayStartTime + ((this.currentPick.from[1]) * this.lessonPeriod),
                this.dayStartTime + ((this.currentPick.to[1]) * this.lessonPeriod),
            ].sort((a, b) => {
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            });
            dates[0] = dates[0] - this.lessonPeriod;
            const [from, to] = dates;
            const dateFrom = this.makeDate(date).add(from, 'hours').format('HH:mm');
            const dateEnd = this.makeDate(date).add(to, 'hours').format('HH:mm');
            return [dateFrom, dateEnd];
        },

        makeDate(date) {
            const [day, month, year] = date[3].split('.');
            moment.locale('ru');
            return new moment(date[3], 'DD.MM.YYYY').locale('ru').utcOffset(0).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            });
        },

        // calcCellPos(row, cell) {
        //     return parseInt(row) * 100 + parseInt(cell);
        // },

        fillDays(startWeek = null, endWeek = null) {
            this.dateFrom = startWeek ? startWeek : (new moment()).startOf('week');
            this.dateTo = endWeek ? endWeek : (new moment()).endOf('week');

            const days = [];
            let day = this.dateFrom;

            while (day <= this.dateTo) {
                const _day = new moment(day).locale(this.$i18n.locale)
                days.push(_day.locale(this.$i18n.locale).format('ddd,DD,MMM,DD.MM.YYYY').split(','));
                day = day.clone().add(1, 'd');
            }
            this.tableDays = days;
        },

        getPickedCellType(cell) {
            if (cell.data.promo_lesson) {
                return 'promo_lesson';
            }
            if (cell.data.freeTime) {
                return 'free_time';
            }
            return false;
        },
        selectRange(evt) {
            if (this.dayPick) {
                const coords = [evt.target.dataset.row - 1, evt.target.dataset.cell - 1];
                const slicedArray = this.daysMatrix.slice(this.ghostPick.from[0] - 1, coords[0] + 1);
                this.ghostPick.to = coords.map(item => item + 1);
                if (this.isCellSelected(coords[0], coords[1])) {
                    this.hoveredCells.push(coords[0], coords[1]);
                }
            }
        },
        buildDataCalendar() {
            this.daysMatrix = [];
            for (let dayNum = 1; dayNum <= this.dayPeriod; dayNum++) {
                const hours = this.fillWeekHours(dayNum);
                this.daysMatrix.push(hours);
            }
        },
        fillTableHeader() {
            const hours = [];
            const dayStart = this.dayStartTime;
            const dayEnd = this.dayEndTime;
            const iter = (dayEnd - dayStart) / this.lessonPeriod;
            const date = moment().utcOffset(0);
            date.set({ hour: dayStart, minute: 0, second: 0 });
            for (let lessonHours = 0; lessonHours < iter; lessonHours++) {
                hours.push(date.format('HH:mm'));
                date.add(this.lessonPeriod, 'hour');
            }
            this.tableHeader = hours;
        },
        fillWeekHours(daynum) {
            const hours = [];
            const dayStart = this.dayStartTime;
            const dayEnd = this.dayEndTime;
            const iter = (dayEnd - dayStart) / this.lessonPeriod;
            const start = moment(this.dateFrom).add('day', daynum - 1);
            const date = moment().utcOffset(0);
            date.set({ hour: dayStart, minute: 0, second: 0 });
            for (let lessonHours = 1; lessonHours <= iter; lessonHours++) {
                hours.push({
                    caption: date.format('HH:mm'),
                    data: this.getHourData(start, date),
                    // data: {
                    //     picked: 0,
                    //     promo_lesson: this.$store.state.common.constants.lesson_types[],
                    // },
                });
                date.add(this.lessonPeriod, 'hour');
            }
            return hours;
        },

        isLessonInRange(item, hour) {
            let hh = parseInt(hour.replace(':', ''));
            let from = parseInt(item.from.replace(':', ''));
            let to = parseInt(item.to.replace(':', ''));
            return from <= hh && hh < to;
        },

        getHourData(fulldate, hours) {
            const date = fulldate.format('DD.MM.YYYY');
            const hour = hours.format('HH:mm');
            // console.log('HOOOURS', hours, hour);
            const data = this.periodLessonsData[date];
            const freeTime = this.freeTime[date];
            if (data) {
                for (const item of data) {
                    // if (item.from === hour || item.to === hour) {
                    if (this.isShowClosedLesson) {
                      if (this.isLessonInRange(item, hour) && item.status === 3) {
                        return {
                          picked: 1,
                          promo_lesson: item.is_promo,
                          details: { ...item },
                        };
                      }
                    }
                  if (this.isLessonInRange(item, hour) && item.status !== 3) {
                    return {
                      picked: 1,
                      promo_lesson: item.is_promo,
                      details: { ...item },
                    };
                  }
                }
            }
            if (freeTime && Array.isArray(freeTime)) {
                for (const day of freeTime) {
                    if (Object.keys(day).length > 0 && this.inFreeTime(hour, day)) {
                        return {
                            freeTime: true,
                            from: day.from,
                            to: day.to,
                            timeId: day.id,
                        };
                    }
                }
            }
            return {
                picked: 0,
                promo_lesson: 0,
            };
        },
        inFreeTime(hour, dateObj) {
            const hourTime = parseInt(hour.replace(':', ''));
            const freeFrom = parseInt(dateObj.from.replace(':', ''));
            const freeTo = parseInt(dateObj.to.replace(':', ''));
            return hourTime >= freeFrom && hourTime < freeTo || hourTime >= freeFrom && freeTo === 0;
        },
        showItemData(item) {
            this.$modal.show(DayDataModal, {
                payload: item,
            }, {
              name: 'DayDataModal',
              width: '20%',
              height: 'auto',
              scrollable: true
            },{
              'before-close': () => {this.setScheduleRefresh()}
            });
        },
    },

    async created() {
        this.fillDays();
        await this.fetchSchedule(this.fetchPayload);
        await this.fetchLessonsSchedule();
        // this.periodLessonsData = this.$store.state.scheduler.schedule;
        // this.freeTime = this.$store.state.scheduler.freeTime;
        this.fillTableHeader();
        this.buildDataCalendar();
    },
};
</script>

<style scoped lang='scss'>
$busy_col: #F57956;
$promo_col: #e0d334;
$na_col: #eeeeee;
$free_col: #b0e8a4;
$picked_col: #f8a354;
$select_col: #D3F69E;
.control {
    &-item {
        display: flex;

        .control-btn {
            color: #4d4d4d;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 50%;
            border: 1px solid #eee;
            transition: .1s;

            &:hover {
                text-decoration: none;
                background: #4d4d4d;
                color: #fff;
            }

            &__left {
                margin-right: 5px;
            }

            &__right {

            }
        }
    }
}

.lesson-dates__blank {
    height: 100%;
    width: 50px;
}

.lesson__picker {
    overflow-x: auto;
    direction: ltr;
    //scrollbar-color: #d4aa70 #e4e4e4;
    //scrollbar-width: 10px;
    padding-bottom: 10px;
    position: relative;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #eee;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        //border: 6px solid rgba(0, 0, 0, 0.18);
        //border-left: 0;
        //border-right: 0;
        background-color: #aaa;
    }

}

.week-area {
    display: flex;
    flex-direction: column;
}

.day {
    &-table {
        //width: 100%;
        //border-collapse: separate;
    }

    &-row {
        display: flex;
    }

    &-time {
        transition: .05s;
        background: #f3f3f3;
        //flex-basis: 1/36;
        //margin: 5px;
        //padding: 5px;
        height: 55px;
        min-width: 100px;
        max-width: 100px;
        box-sizing: border-box;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        margin: 3px;
        overflow: hidden;
    }

    &-data {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background: #fff;
        position: relative;
        bottom: -5px;
        transition: .1s;

        &:hover {
            background-color: #eee;
        }

        .name {
            color: #4d4d4d;
            font-weight: 600;
            font-size: 10px;
            text-align: center;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.selected {
    background: $picked_col;
}

.selected_transparent {
    background: rgba(#ffff00, .1);
}

.selected_red {
    border: none;
    background: $select_col;
    color: #fff;
}

.selected_free {
    border: none;
    background: $free_col;
    color: #fff;
}

.select_promo {
    background: $promo_col;
}

.selected_block {
    background: rgba(#000, .3);
}

.table__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    margin-left: 111px;

    .header__hour {
        //min-width: 100px;
        min-width: 106px;
        position: relative;

        //&:first-child {
        //    span {
        //        position: relative;
        //        left: 0px;
        //    }
        //}

        span {
            position: relative;
            left: -15px;
            font-size: 12px;
            font-weight: 600;
            color: #1b1e21;
        }

        &:last-child {
            position: relative;
            right: 100px;
            text-align: right;

            span {
                position: relative;
                left: 0px;
            }
        }
    }
}

.day {
    text-align: center;
    line-height: 1;

    &__wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &_name {
    }

    &_date {
        font-weight: 600;
        font-size: 24px;
    }

    &_month {

    }
}

.days__container {
    position: sticky;
    left: 0;
    background: #fff;
    padding: 0 10px;
    box-shadow: 0px 0px 10px rgba(#000, .2);
    z-index: 100;
}

.day__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    //height: 100%;
    height: 55px;
}

.legend {
    display: flex;
    margin-left: auto;
}

.legend__item {
    margin: 5px 15px;
    display: flex;
}

.legend__descr {
    font-weight: 600;
    font-size: 12px;
}

.legend__col {
    width: 30px;
    height: 20px;
    border-radius: 5px;
    margin-right: 5px;

    &_busy {
        //background: $busy_col;
        background: $na_col;
    }

    &_promo {
        background: $promo_col;
    }

    &_picked {
        background: $picked_col;
    }

    &_na {
        background: $na_col;
    }

    &_free {
        background: $free_col;
    }
}
</style>