<template>
    <b-form @submit.prevent="saveHomework(false)">
        <b-form-group label="Задание:" class="col-12" label-for="input-task_title">
            <b-form-textarea
                id="input-task_title"
                class="textarea"
                v-model="form.task"
            />
        </b-form-group>

        <b-form-group label="Комментарий:" class="col-12" label-for="input-comment_title">
            <b-form-textarea
                id="input-comment_title"
                class="textarea"
                v-model="form.comment"
            />
        </b-form-group>

        <b-form-group label="Статус:" class="col-12" label-for="input-status">
            <multiselect
                v-if="statuses && statuses.length > 0"
                id="input-status"
                label="title"
                :close-on-select="true"
                :options="statuses"
                :value="statuses.filter(v => v.id === form.status)[0]"
                @input="(data) => form.status = data.id"
            />
        </b-form-group>

        <template v-if="files && Object.keys(files).length > 0">
            <div v-if="files.task.length > 0">
                <h4>Файлы задания</h4>
                <div class="col-12 file-listing">
                    <div
                        class="file"
                        v-for="(file, key) in files.task"
                        :key="key"
                        :class="{'file-delete': file.delete}"
                    >
                        <div class="file-icone">
                                <span class="file-icone-remove" @click="removeFile( 'task', key )">
                                  <svg width="20" height="20" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                      <path
                                          d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z" />
                                      <path
                                          d="M64.2422,31.7578a5.9979,5.9979,0,0,0-8.4844,0L48,39.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844L39.5156,48l-7.7578,7.7578a5.9994,5.9994,0,1,0,8.4844,8.4844L48,56.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844L56.4844,48l7.7578-7.7578A5.9979,5.9979,0,0,0,64.2422,31.7578Z" />
                                    </g>
                                  </svg>
                                </span>
                            <svg width="40" height="40" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 4c-2.21 0-3.98 1.79-3.98 4l-.02 32c0 2.21 1.77 4 3.98 4h24.02c2.21 0 4-1.79 4-4v-24l-12-12h-16zm14 14v-11l11 11h-11z" />
                                <path d="M0 0h48v48h-48z" fill="none" />
                            </svg>
                        </div>
                        <a class="file-name" :href="file.url" download>
                            {{ file.original_name }}
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="files.answer && files.answer.length > 0">
                <h4>Файлы решения</h4>
                <div class="col-12 file-listing">
                    <div
                        class="file"
                        v-for="(file, key) in files.answer"
                        :key="key"
                        :class="{'file-delete': file.delete}"
                    >
                        <div class="file-icone">
                                <span class="file-icone-remove" @click="removeFile( 'answer', key )">
                                  <svg width="20" height="20" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                      <path
                                          d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z" />
                                      <path
                                          d="M64.2422,31.7578a5.9979,5.9979,0,0,0-8.4844,0L48,39.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844L39.5156,48l-7.7578,7.7578a5.9994,5.9994,0,1,0,8.4844,8.4844L48,56.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844L56.4844,48l7.7578-7.7578A5.9979,5.9979,0,0,0,64.2422,31.7578Z" />
                                    </g>
                                  </svg>
                                </span>
                            <svg width="40" height="40" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 4c-2.21 0-3.98 1.79-3.98 4l-.02 32c0 2.21 1.77 4 3.98 4h24.02c2.21 0 4-1.79 4-4v-24l-12-12h-16zm14 14v-11l11 11h-11z" />
                                <path d="M0 0h48v48h-48z" fill="none" />
                            </svg>
                        </div>
                        <a class="file-name" :href="file.url" download>
                            {{ file.original_name }}
                        </a>
                    </div>
                </div>
            </div>
        </template>

        <div class="col-12 file-uploader">
            <label for="input-files">
                Добавить файлы
            </label>
            <input
                id="input-files"
                type="file"
                :accept="acceptTypes"
                ref="uploaded_files"
                @change="handleFileUpload()"
                multiple
            />
        </div>

        <div class="col-12 file-listing">
            <div class="file" v-for="(file, key) in form.uploaded_files" :key="key">
          <span class="file-icone">
            <span class="file-icone-remove" @click="removeUploadedFile(key)">
            <svg width="20" height="20" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                    d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z" />
                <path
                    d="M64.2422,31.7578a5.9979,5.9979,0,0,0-8.4844,0L48,39.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844L39.5156,48l-7.7578,7.7578a5.9994,5.9994,0,1,0,8.4844,8.4844L48,56.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844L56.4844,48l7.7578-7.7578A5.9979,5.9979,0,0,0,64.2422,31.7578Z" />
              </g>
            </svg>
          </span>
            <svg width="40" height="40" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path class="cls-1"
                    d="M20.535,7.122,14.879,1.464A4.981,4.981,0,0,0,13.462.487c-.035-.018-.066-.04-.1-.055A4.984,4.984,0,0,0,11.343,0H6A4,4,0,0,0,2,4V20a4,4,0,0,0,4,4H18a4,4,0,0,0,4-4V10.657a4.968,4.968,0,0,0-.433-2.016.85.85,0,0,0-.055-.1A4.976,4.976,0,0,0,20.535,7.122ZM18.586,8H16a2,2,0,0,1-2-2V3.414ZM20,20a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h5.343A3,3,0,0,1,12,2.078V6a4,4,0,0,0,4,4h3.92a2.953,2.953,0,0,1,.08.657Z" />
              <path class="cls-1"
                    d="M13,15H11V13a1,1,0,0,0-2,0v2H7a1,1,0,0,0,0,2H9v2a1,1,0,0,0,2,0V17h2a1,1,0,0,0,0-2Z" />
            </svg>
          </span>
                <span class="file-name">{{ file.name }}</span>
            </div>
        </div>

        <b-form-group class="col-12 text-right">
            <b-button type="button" class="mr-2" variant="outline-primary" @click="handleReviewClick">
                <span>Отправить на доработку</span>
            </b-button>
            <b-button type="submit" class="mr-2" variant="success">
                <span>Сохранить</span>
            </b-button>
            <b-button type="reset" variant="primary" @click="closeModal">Закрыть</b-button>
        </b-form-group>
    </b-form>
</template>

<script>
import { formPreparation } from "@/mixins/form/form-mixin";
import Multiselect from "vue-multiselect";

export default {
    name: "HomeworkWithFiles",
    components: {
        Multiselect,
    },
    mixins: [formPreparation],
    props: {
        homework: {
            required: true,
        },
        lessonId: {
            required: true,
            type: [Number, String],
        },
        statuses: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            form: {
                link: null,
                task: null,
                comment: null,
                status: null,
                uploaded_files: [],
                remove_files: [],
            },
            files: {},
            acceptTypes: "image/jpeg, image/png, text/plain, application/msword, application/pdf, .docx",
        };
    },
    created() {
        if (this.homework) {
            Object.keys(this.homework).forEach(key => {
                if (key === "files") {
                    let files = this.homework[key];

                    Object.keys(files).forEach(j => {
                        files[j].forEach(file => {
                            file.delete = false;
                        });
                    });

                    this.files = files;
                } else if (key === "link") {
                    this.form[key] = null;
                } else {
                    this.form[key] = this.homework[key];
                }
            });
        }
      console.log(this.lessonId)
    },
    methods: {
        async handleReviewClick() {
            await this.saveHomework(true);
        },
        async saveHomework(review = false) {
            this.form.lesson_id = this.lessonId;

            Object.keys(this.files).forEach(key => {
                this.files[key].forEach(file => {
                    if (file.delete) {
                        this.form.remove_files.push(file.id);
                    }
                });
            });

            // костыль, чтобы не искать все места где на бэке менять код, т.к роут старый.
            if (this.form.status) {
                this.form.status = this.statuses.filter(v => v.id === this.form.status)[0];
            }

            // если указана доработка, принудительно ставим статус "Новое"
            if (review) {
                this.form.status = this.statuses.filter(v => v.id === 0)[0];
            }

            const formData = this.formPreparation();

            try {
                await this.$axios.post("api/homework/update-or-create", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                this.$toasted.success("Домашнее задание сохранено", {
                    position: "bottom-right",
                    action: {
                        text: "Закрыть",
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });

                this.$emit("on-save");
            } catch (e) {
                if (e?.response?.status === 400) {
                    this.$toasted.error("Заполните корректно все поля", {
                        position: "bottom-right",
                        action: {
                            text: "Закрыть",
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });
                } else {
                    this.$toasted.error("Попробуйте снова или обратитесь в поддержку", {
                        position: "bottom-right",
                        action: {
                            text: "Закрыть",
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });

                    console.log("Can not create or update homework: " + e);
                }
            }
        },
        handleFileUpload() {
            let uploadedFiles = this.$refs.uploaded_files.files;

            for (let i = 0; i < uploadedFiles.length; i++) {
                this.form.uploaded_files.push(uploadedFiles[i]);
            }
        },
        removeUploadedFile(key) {
            this.form.uploaded_files.splice(key, 1);
        },
        removeFile(type, key) {
            const typeCopy = JSON.parse(JSON.stringify(this.files[type]));

            typeCopy[key].delete = !typeCopy[key].delete;
            this.$set(this.files, type, typeCopy);
        },
        closeModal() {
            this.$modal.hide("HomeworkModal");
        },
    },
};
</script>

<style lang="scss" scoped>
.textarea {
    width: 100%;
    min-height: 80px;
}

.file-uploader {
    label {
        cursor: pointer;
        color: #1A86D0;

        &:hover {
            color: #FD5F00;
        }
    }

    input {
        display: none;
    }
}

.file-listing {
    display: flex;
    flex-wrap: wrap;
}

.file {
    display: flex;
    max-width: 80px;
    flex-flow: column;
    padding: 10px 10px 10px;
    text-align: center;

    &-delete {
        opacity: 0.5;

        .file-icone-remove {
            fill: #1A86D0;
            transform: rotate(45deg);
        }
    }

    &-icone {
        max-width: 40px;
        margin: 0 auto;
        display: inline;
        position: relative;

        &-remove {
            cursor: pointer;
            position: absolute;
            top: -8px;
            right: -2px;
            z-index: 2;
            fill: #FD5F00;
        }
    }

    &-name {
        font-size: 10px;
        overflow: hidden;
        overflow-wrap: break-word
    }
}
</style>