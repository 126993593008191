<template>
  <div>
    <div>
      <h4 class="mb-4 h4-title">
        Занятия
      </h4>
    </div>
    <div class="widget">
      <div class="row">
        <div class="col-sm-12 text-right">
          <button class="btn btn-primary" @click="openLessonDetails(null, false)">Добавить время</button>
        </div>
      </div>
      <hr/>
      <WeeksPicker :onChangeDate="onChangeDatePeriod"/>
      <hr/>

      <div v-if="!getLessonsSchedule || !getLessonsSchedule.length" class="no_data">
        Нет данных для отображения
      </div>
      <div v-else class="schedule">

        <div class="hours-line">
          <div class="text-center" style="height: 26px;">&nbsp;</div>
          <div class="hour" :style="{ top: hour.position, height: hour.height }" v-for="hour in timeLine"
               :key="hour.caption">
            {{ hour.caption }}
          </div>
        </div>
        <div class="days-container">
          <div class="day-item" v-for="item in shedule" :key="item.day">
            <div class="day-title">{{ item.day | toDayTitle }}</div>
            <div class="hours-line" :style="{ height: dayHeight }">
              <div class="sep" :style="{ top: hour.position, height: hour.height }"
                   v-for="hour in timeLine"
                   :key="hour.caption">
              </div>

              <div v-for="(subitem, index) in item.items" :key="index"
                   :class="{ 'lesson-time': subitem.isLesson, 'free-time': !subitem.isLesson, 'trial': (subitem.lesson_type == 1) }"
                   :style="{ top: subitem.position, height: subitem.height }"
                   :title="subitem.isLesson && subitem.student ? subitem.student.full_name + ', ' + subitem.student.age + ' лет, ' + subitem.status_title : ''"
                   :data-id="subitem.id"
                   @click="openLessonDetails(subitem, subitem.isLesson)">
                <span v-if="subitem.isLesson">{{ subitem | toDayRange }}</span>
                <span v-if="subitem.isLesson && subitem.status == 1" class="dot finished">&nbsp;</span>
                <span v-if="subitem.isLesson && subitem.status == 2" class="dot skipped">&nbsp;</span>
                <span v-if="subitem.isLesson && subitem.status == 3" class="dot canceled">&nbsp;</span>
              </div>

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EditTimeModal from '@/components/Modal/EditTimeModal';
import EditLessonModal from '@/components/Modal/EditLessonModal';
import WeeksPicker from '@/components/WeeksPicker/WeeksPicker';
import moment from 'moment';

export default {
  name: 'TeacherCalendar',
  components: {WeeksPicker},
  data: function () {
    return {
      h10min: 10, // высота 10 минут в пикселях
      teacher_id: null,
      currentDate: new Date(),
    };
  },
  filters: {
    toDayTitle(value) {
      return moment(value).format('ddd DD MMM');
    },
    toDayRange(item) {
      const from = moment(item.date_from).format('HH:mm');
      const to = moment(item.date_to).format('HH:mm');
      return `${from} - ${to}`;
    }
  },
  computed: {
    ...mapGetters({
      getLessonsSchedule: 'lessons/getLessonsSchedule',
      getAvailableDaysShedule: 'lessons/getAvailableDaysShedule',
      getDayHoursRange: 'lessons/getDayHoursRange',
    }),
    shedule: function () {
      const zeroPoint = this.getDayHoursRange.dayStartHour * 60;
      const schedule = {};
      for (const item of this.getLessonsSchedule) {
        schedule[item.day] = {...item};
        for (const lesson of schedule[item.day].items) {
          lesson.isLesson = true;
        }
      }
      for (const item of this.getAvailableDaysShedule) {
        if (typeof (schedule[item.day]) !== 'undefined') {
          schedule[item.day].items = [...schedule[item.day].items, ...item.items];
        }
      }
      // рассчитываем позицию и высоту элементов
      for (const key of Object.keys(schedule)) {
        if (!schedule[key].items) continue;
        for (const item of schedule[key].items) {
          const from = parseInt(moment(item.date_from).format('H')) * 60 + parseInt(moment(item.date_from).format('m')); // hour * 60 + minutes = total minutes
          const to = parseInt(moment(item.date_to).format('H')) * 60 + parseInt(moment(item.date_to).format('m'));
          // позиция
          item.position = (from - zeroPoint) + 'px';
          // высота
          item.height = (to - from) + 'px';
        }
      }
      console.error('schedule', Object.values(schedule));
      return Object.values(schedule);
    },
    // построение временной шкалы на основе часа когда начинается рабочий день и когда заканчивается
    timeLine: function () {
      const start = this.getDayHoursRange.dayStartHour;
      const end = this.getDayHoursRange.dayEndHour;
      if (start > end) return [];
      let index = 0;
      let now = start;
      const timeline = [];
      while (now <= end) {
        timeline.push({
          // час
          caption: (`${now}`.length > 1 ? now : `0${now}`) + ':00', // добавляем ноль в начале, если нужно
          // позиция
          position: (this.h10min * 6 * index) + 'px',
          // высота
          height: (this.h10min * 6) + 'px'
        });
        now += 1;
        index += 1;
      }
      return timeline;
    },
    dayHeight: function () {
      const start = this.getDayHoursRange.dayStartHour;
      const end = this.getDayHoursRange.dayEndHour;
      if (start > end) return '600px';
      let number = (end - start + 1) * (this.h10min * 6);
      return `${number}px`;
    }
  },
  methods: {
    ...mapActions({
      fetchLessonsSchedule: 'lessons/fetchLessonsSchedule',
      fetchAvailableDaysSchedule: 'lessons/fetchAvailableDaysSchedule',
      updateLessonStatusDetails: 'lessons/updateLessonStatusDetails',
      createLesson: 'lessons/createLesson',
      removeLesson: 'lessons/removeLesson',
      saveCalendarTime: 'lessons/saveCalendarTime',
      removeCalendarTime: 'lessons/removeCalendarTime',
    }),
    onChangeDatePeriod(event) {
      this.currentDate = event.date;
      this.loadShedule();
    },
    async loadShedule() {
      const date = moment(this.currentDate).format('YYYY-MM-DD');
      await this.fetchAvailableDaysSchedule({date, teacher_id: this.teacher_id});
      await this.fetchLessonsSchedule({date, teacher_id: this.teacher_id});
    },
    openLessonDetails(item, isLesson = false) {
      if (item && item.isLesson) {
        this.$modal.show(
            EditLessonModal,
            {
              limited: true,
              record: item,
              actionHandler: this.handleUpdateAction,
              removeHandler: this.handleRemoveAction,
            },
            {
              height: 'auto',
              maxHeight: 900,
              scrollable: true,
            }
        );
      }
      if (!isLesson) {
        this.$modal.show(
            EditTimeModal,
            {
              create: !item,
              record: item ? item : {},
              actionHandler: this.handleSaveTimeAction,
              removeHandler: this.handleRemoveTimeAction,
            },
            {
              height: 'auto',
              maxHeight: 900,
              scrollable: true,
            }
        );
      }
    },
      async handleUpdateAction(data) {
          // TODO сделать обновление данных календаря
      },
    async handleSaveTimeAction(data) {
      const result = await this.saveCalendarTime(data);
      if (result.status === 'success') {
        this.showAlert('Данные сохранены!');
        this.loadShedule();
        return true;
      }
      this.showError('Ошибка сохранения: ' + result.errors[0]);
      return false
    },
    async handleRemoveAction(data) {
      // учитель не может удалять
    },
    async handleRemoveTimeAction(data) {
      const result = await this.removeCalendarTime(data);
      this.showAlert('Временной интервал удален!');
      this.loadShedule();
    },
    showAlert(text) {
      this.$toasted.success(text, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      });
    },
    showError(text) {
      this.$toasted.error(text, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      });
    }
  },
  created() {
    this.loadShedule();
  }
}
</script>

<style scoped lang="scss">
.h4-title {

}

.teacher-wrap {
  margin-bottom: 20px;

  span {
    font-size: 16px;
  }

  .teacher-selector {
    max-width: 300px;
  }
}

.schedule {
  position: relative;
  padding-left: 38px;
  /*min-width: 940px;*/
  /*overflow: auto;*/

  .hours-line {
    position: absolute;
    left: 0;
    top: 0;

    .hour {
      font-size: 12px;
      border-bottom: 1px solid gray;
    }
  }

  .days-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: start
  }

  .day-item {
    flex: 1 0 auto;
    margin: 4px;

    .day-title {
      text-align: center;
      height: 22px;
    }

    .hours-line {
      position: relative;
      height: 600px;
      background-color: #efefef;

      .sep {
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid gray;
      }

      .free-time {
        position: absolute;
        z-index: 100;
        width: 100%;
        min-height: 25px;
        //background-color: #b0c5f5;
        background-color: rgba(176, 197, 245, 0.75);
        text-align: center;
        box-sizing: border-box;
        padding: 3px;
      }

      .lesson-time {
        position: absolute;
        z-index: 200;
        width: 100%;
        min-height: 25px;
        color: #fff;
        background-color: #326709;
        text-align: center;
        box-sizing: border-box;
        padding: 3px;
        border-bottom: 1px solid #fff;
        cursor: pointer;

        &.trial {
          background-color: #e0d334;
          color: #000;
        }
        .dot {
          position: absolute;
          bottom: 4px;
          right: 4px;
          height: 8px;
          width: 8px;
          border-radius: 8px;

          &.finished {
            background-color: #0EA804;
          }
          &.canceled {
            background-color: #0b16a8;
          }
          &.skipped {
            background-color: #a8241d;
          }
        }
      }
    }
  }
}
</style>