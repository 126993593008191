<template>
    <div class="modal-homework">
        <h4 class="col-12 mb-4">Домашнее задание для {{ lesson.student.full_name }} на {{ dateTimeLesson }}</h4>

        <b-tabs>
            <b-tab title="С файлами">
                <Preloader v-if="loading" />

                <HomeworkWithFiles
                    v-else
                    :lesson-id="lesson.id"
                    :statuses="statuses"
                    :homework="homework"
                    @on-save="handleSave"
                />
            </b-tab>

            <b-tab title="С ссылкой">
                <Preloader v-if="loading" />

                <HomeworkWithLink
                    v-else
                    :lesson-id="lesson.id"
                    :statuses="statuses"
                    :homework="homework"
                    @on-save="handleSave"
                />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import HomeworkWithFiles from "@/components/Modal/homework/HomeworkWithFiles";
import HomeworkWithLink from "@/components/Modal/homework/HomeworkWithLink";
import moment from "moment/moment";

export default {
    name: "HomeworkModal",
    components: { Preloader, HomeworkWithFiles, HomeworkWithLink },
    props: {
        lesson: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
            homework: null,
            statuses: [],
        };
    },
    async created() {
        this.loading = true;
        await this.getHomework();
        await this.getHomeworkStatuses();
        this.loading = false;
    },
    computed: {
        dateTimeLesson() {
            return `${moment(this.lesson.date_from).format('DD.MM.YYYY HH:mm')}`;
        },
    },
    methods: {
        async getHomework() {
            try {
                const { data } = await this.$axios.get(`api/homework/get-by-lesson/${this.lesson.id}`);
                this.homework = data.data;
                if (data.data) {
                    this.homework.uploaded_files = [];
                    // this.$set(this.homework, 'uploaded_files', []);
                }
            } catch (ex) {
                if (ex?.response?.status === 404) {
                    this.$toasted.success("Домашнее задание отсутствует. Можете его создать", {
                        position: "bottom-right",
                        action: {
                            text: "Закрыть",
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });
                } else {
                    console.log("Can not get homework: " + ex);
                }
            }
        },
        async getHomeworkStatuses() {
            try {
                const { data } = await this.$axios.get(`api/homework/get-statuses`);
                this.statuses = data;
            } catch (ex) {
                console.log("Can not get statuses homework: ", ex);
            }
        },
        async handleSave() {
            this.loading = true;
            await this.getHomework();
            this.loading = false;
        },
    },
};
</script>

<style scoped lang="scss">
.modal-homework {
    padding: 20px;
}
</style>