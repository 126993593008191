<template>
    <div class='day-wrap'>
      <template v-if="isShowClosedLesson">
        <EmptyCell
            v-if='isEmpty || day.data.details && day.data.details.status !== 3'
            @click='selectDays'
            @mouseover='selectRange'
            :cell='cellIdx'
            :row='rowIdx'
            :date='date'
            :cell-data='day'
            :week='week'
            @selectDone='$emit("selectDone")'
        />
        <FreeCell
            v-else-if='day.data.freeTime'
            :cell='cellIdx'
            :row='rowIdx'
            :date='date'
            :cell-data='day'
        />
        <DataCell v-else-if='day.data.details && day.data.details.status === 3' :lesson='day.data'/>
      </template>
      <template v-else>
        <EmptyCell
            v-if='isEmpty || day.data.details && day.data.details.status === 3'
            @click='selectDays'
            @mouseover='selectRange'
            :cell='cellIdx'
            :row='rowIdx'
            :date='date'
            :cell-data='day'
            :week='week'
            @selectDone='$emit("selectDone")'
        />
        <FreeCell
            v-else-if='day.data.freeTime'
            :cell='cellIdx'
            :row='rowIdx'
            :date='date'
            :cell-data='day'
        />
        <DataCell v-else-if='day.data.details && day.data.details.status !== 3' :lesson='day.data'/>
      </template>
    </div>
</template>

<script>
import EmptyCell from './EmptyCell';
import FreeCell from './FreeCell';
import DataCell from './DataCell';
import {mapGetters} from "vuex";

export default {
    name: 'LessonDay',
    props: ['day', 'rowIdx', 'cellIdx', 'ghostPick', 'date', 'week'],
    components: { EmptyCell, FreeCell, DataCell },
    computed: {
        ...mapGetters({
          isShowClosedLesson: 'scheduler/getIsShowClosedLesson',
        }),
        isEmpty() {
            return !(this.day.data.freeTime || this.day.data.picked > 0);
        },
        isCellSelected() {
            const row = this.rowIdx;
            const cell = this.cellIdx;
            // if (this.dayPick) {
            if (this.ghostPick.from && this.ghostPick.to && this.nearMinIndex) {
                const coords = this.calcCellPos(row, cell);
                const curPoint = this.ghostPick.from.reduce((a, b) => this.calcCellPos(a, b));
                const hovPoint = this.ghostPick.to.reduce((a, b) => this.calcCellPos(a, b));
                const startPoint = this.nearMinIndex.reduce((a, b) => this.calcCellPos(a + 1, b + 1));
                const endPoint = this.nearMaxIndex.reduce((a, b) => this.calcCellPos(a + 1, b + 1));

                if (curPoint >= hovPoint) {
                    if (coords >= hovPoint && coords <= curPoint && hovPoint >= startPoint) {
                        return true;
                    }
                } else {
                    if (coords >= curPoint && coords <= hovPoint && hovPoint <= endPoint) {
                        return true;
                    }
                }
            }
            // }
            return false;
        },
    },
    methods: {
        calcCellPos(row, cell) {
            return parseInt(row) * 100 + parseInt(cell);
        },
        selectRange() {

        },
        selectDays(evt) {

        },
    },
};
</script>

<style lang='scss' scoped>

.day {
    &-table {
        //width: 100%;
        //border-collapse: separate;
    }

    &-row {
        display: flex;
    }

    &-wrap {
        height: 55px;
        margin: 3px;
        overflow: hidden;
        //height: 55px;
        width: 100px;
    }

    &-time {

        //flex-basis: 1/36;
        //margin: 5px;
        //padding: 5px;
        @include cell;
        overflow: hidden;


        &-empty {
            transition: .05s;
            background: #f3f3f3;
            @include cell;

            &:hover {
                background-color: #eee;
            }
        }

    }

    &-data {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background: #fff;
        position: relative;
        bottom: -5px;
        transition: .1s;

        //&:hover {
        //    background-color: #eee;
        //}

        .name {
            color: #4d4d4d;
            font-weight: 600;
            font-size: 10px;
            text-align: center;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.selected {
    background: $picked_col;
}

.selected_transparent {
    background: rgba(#ffff00, .1);
}

.selected_red {
    border: none;
    background: $select_col;
    color: #fff;
}

.selected_free {
    border: none;
    background: $free_col;
    color: #fff;
}

.selected_block {
    background: rgba(#000, .3);
}
</style>